import {RESOURCES} from "./MyRestConfig";
import {API_VERBS} from "../MyApiVerbs";

import {FEATURES_V1_2_ENUMS, enabledFeatures} from "../../Configs";
const ATTN_SPLIT = enabledFeatures.includes(FEATURES_V1_2_ENUMS.ATTN_SPLIT_WIP);

export const operatorUrls = {
    //special
    '!AUTH!': {
        [API_VERBS.LOGIN]:{url:'/Operators/login', method:'POST', anonAccess:true},
        [API_VERBS.RESETPASSRQ]:{url:'/Operators/reset', method:'POST', anonAccess:true},
        [API_VERBS.NEWPASSRQ]:{url:'/Operators/reset-password', method:'POST', anonAccess:true},
        //disabling for 1.1 beta on request
        [API_VERBS.REGISTER]:{url:'/Operators', method:'POST', anonAccess:true},
        [API_VERBS.REGISTERCONFIRM]:{url:'/Operators/confirm', method:'GET', anonAccess:true}, //params as query!

        [API_VERBS.LOADSELF]:{
            url:'/Operators/{userId}',
            method:'GET',
            //customFilter:{"include": { "relation": "units"}},
        },
        [API_VERBS.SAVESELF]:{
            url:'/Operators/{userId}',
            method:'PATCH',
        },
        [API_VERBS.PUT_NICKNAME]: {
            url:'/Operators/{userId}/nickname',
            method:'PUT',
        },
        [API_VERBS.REQUEST_NICKNAMES]: {
            url:'/Operators/nickname',
            method:'POST',
        },
        //TODO remove name method inconsistence on back-end and front-end - PATCH or POST?
        [API_VERBS.PATCH_PHONE]: {
            url:'/Operators/send-code',
            method:'POST'
        },
        [API_VERBS.VERIFY_PHONE]: {
            url:'/Operators/verify-code',
            method:'POST'
        }

    },

    //after switching to attachment relation (customized endpoints)
    [RESOURCES.DRONES]: {
        [API_VERBS.COUNT]:{url:'/Operators/{userId}/drones/count', method:'GET'},

        [API_VERBS.LB_GET_MANY]:{url:'/Operators/{userId}/drones',
            customFilter: {
                "fields":{"attn":false} //to cleanup
            },
            method:'GET'},
        [API_VERBS.LB_GET_ONE]:{url:'/Operators/{userId}/drones/{modelId}', method:'GET'},

        [API_VERBS.LB_UPDATE]:(ATTN_SPLIT)
            ? {url:'/Operators/{userId}/dronesUpdateAttributes/{modelId}', method:'PUT'}
            : {url:'/Operators/{userId}/drones/{modelId}', method:'PUT'},

        [API_VERBS.LB_CREATE]:(ATTN_SPLIT)
            ? {url:'/Operators/{userId}/dronesCreate', method:'POST'}
            : {url:'/Operators/{userId}/drones', method:'POST'},

        [API_VERBS.LB_DELETE]:{url:'/Operators/{userId}/drones/{modelId}', method:'DELETE'}, //backend archives, not deletes

    },

    [RESOURCES.DRONES_ARCHIVE]: {
        [API_VERBS.COUNT]:{url:'/Operators/{userId}/drones/count', method:'GET'},

        [API_VERBS.LB_GET_MANY]:{url:'/Operators/{userId}/drones',
            customFilter: {
                "fields":{"attn":false}
            },
            method:'GET'},
        [API_VERBS.LB_GET_ONE]:{url:'/Operators/{userId}/drones/{modelId}', method:'GET'},

        [API_VERBS.LB_UPDATE]:{url:'/Operators/{userId}/drones/{modelId}', method:'PUT'},

    },

    //after switching to attachment relation (customized endpoints)
    [RESOURCES.CERTS]: {
        [API_VERBS.COUNT]:{url:'/Operators/{userId}/certs/count', method:'GET'},

        [API_VERBS.LB_GET_MANY]:{url:'/Operators/{userId}/certs',
            customFilter: {
                "fields":{"attn":false}, //to cleanup
            },
            method:'GET'},
        [API_VERBS.LB_GET_ONE]:{url:'/Operators/{userId}/certs/{modelId}', method:'GET'},

        [API_VERBS.LB_UPDATE]:(ATTN_SPLIT)
            ? {url:'/Operators/{userId}/certsUpdateAttributes/{modelId}', method:'PUT'}
            : {url:'/Operators/{userId}/certs/{modelId}', method:'PUT'},

        [API_VERBS.LB_CREATE]:(ATTN_SPLIT)
            ? {url:'/Operators/{userId}/certsCreate', method:'POST'}
            : {url:'/Operators/{userId}/certs', method:'POST'},

        [API_VERBS.LB_DELETE]:{url:'/Operators/{userId}/certs/{modelId}', method:'DELETE'}, //backend archives, not deletes

    },

    [RESOURCES.CERTS_ARCHIVE]: {
        [API_VERBS.COUNT]:{url:'/Operators/{userId}/certs/count', method:'GET'},

        [API_VERBS.LB_GET_MANY]:{url:'/Operators/{userId}/certs',
            customFilter: {
                "fields":{"attn":false}
            },
            method:'GET'},
        [API_VERBS.LB_GET_ONE]:{url:'/Operators/{userId}/certs/{modelId}', method:'GET'},

        [API_VERBS.LB_UPDATE]:{url:'/Operators/{userId}/certs/{modelId}', method:'PUT'},

    },

    [RESOURCES.MISSIONS]: {
        [API_VERBS.COUNT]:{url:'/Operators/{userId}/missions/count', method:'GET'},

        [API_VERBS.LB_GET_MANY]: {
            url: '/Operators/{userId}/missions',
            method: 'GET',
            customFilter: {
                // --> seems that RA compares api calls between list view and edit view,
                // but unfortunately shallow. In my opinion GET_LB_MANY and GET_LB_ONE 'include' keys where
                // compared as equal so optimistic rendering did not attach zones in Edit component.
                // Disabling for operator now as he doesn't need that relation in list now. That makes the
                // difference big enough to attach the zones by GET_LB_ONE call in Edit component
                // "include":
                //     {
                //         "relation": "legs",
                //         "scope": {"include": "zoneLeg"}
                //     },
                "fields":{"attn":false}
            }
        },

        //is (no filter in GET /Operators/{id}/missions/{fk})
        [API_VERBS.LB_GET_ONE]:{
            url: '/Operators/{userId}/missions',
            method: 'GET',
            customFilter: {
                "where": {"uid": "{modelId}"},
                "include": {
                    "relation": "legs",
                    "scope": {
                            "include": [
                                {
                                    "relation": "zones",
                                    "scope": {
                                        "include": [
                                            {
                                                "relation": "unit", //since there is no endpoint for units for operator, we have to do it this way
                                                //"fields": {"attn": false},
                                            }
                                        ]
                                    }
                                },
                                {
                                    "relation": "zoneLeg", //for status
                                }
                            ]
                        }
                }
            },
        },

        [API_VERBS.LB_UPDATE]:(ATTN_SPLIT)
            ?{url:'/Operators/{userId}/missionsUpdateAttributes/{modelId}', method:'PUT'}
            :{url:'/Operators/{userId}/missions/{modelId}', method:'PUT'},

        [API_VERBS.LB_CREATE]:(ATTN_SPLIT)
            ?{url:'/Operators/{userId}/missionsCreate', method:'POST'}
            :{url:'/Operators/{userId}/missions', method:'POST'},


            //special endpoint for archiving missions
        [API_VERBS.LB_DELETE]:{url:'/Operators/{modelId}/mission/archive', method:'POST'},

    },

    [RESOURCES.MISSIONSV2_DASHBOARD]: {
        [API_VERBS.COUNT]:{url:'/Operators/{userId}/missions/count', method:'GET'},
        //fixme -> temporary solution,
        //dashboard probably should have special endpoint.
        [API_VERBS.LB_GET_MANY]:{url:'/Operators/{userId}/missions',
            method:'GET',
            customFilter: {
                "fields":{"attn":false},
                //now called as custom filters per status
                //"where": {"status": { "neq": null } },
            },
        },
    },


    [RESOURCES.DRONE_MODELS]: {
        [API_VERBS.COUNT]:{url:'/Dronemodels/count', method:'GET'},

        [API_VERBS.LB_GET_MANY]:{url:'/Dronemodels', method:'GET'},
        [API_VERBS.LB_GET_ONE]:{url:'/Dronemodels/{modelId}', method:'GET'},

    },

    //only to serialize mission document data (no special endpoint for that)
    //messy i think..
    [RESOURCES.LEGS]: {
        //missionid?! damn make params one day...
        [API_VERBS.LB_CREATE]:{url:'/Operators/{referenceId}/legs', method:'POST'}, //damn! modelId is MissionID! some better format here (another replace case in rest)
        [API_VERBS.LB_UPDATE]:{url:'/Operators/{modelId}/legs', method:'PUT'}, //modelId is legID!
        [API_VERBS.LB_DELETE]:{url:'/Operators/{modelId}/legs', method:'DELETE'}, //modelId is legID!

    },

    //not used now, keeping -  as we may need public files soon
    [RESOURCES.CONTAINERS]: {
        [API_VERBS.COUNT]:{url:'/Containers/count', method:'GET'},

        [API_VERBS.LB_GET_MANY]:{url:'/Containers', method:'GET'},
        [API_VERBS.LB_GET_ONE]:{url:'/Containers/{containerId}', method:'GET'},

        //[API_VERBS.DOWNLOAD]:{url:'/Containers/{containerId}/download/{fileId}', method:'GET'}
        [API_VERBS.UPLOAD]:{url:'/Containers/{userId}/upload', method:'POST'},
        [API_VERBS.DOWNLOAD]:{url:'/Containers/{userId}/download/{fileName}', method:'GET'},
    },

    //required by map at least now...
    //todo -> discuss what's difference between gov/op/public gets
    [RESOURCES.ZONES_ALL]: {
        [API_VERBS.COUNT]:{url:'/Zones/count', method:'GET'},

        [API_VERBS.LB_GET_MANY]:{url:'/Zones', method:'GET'},
        //[API_VERBS.GET_LB_ONE]:{url:'/Zones/{modelId}', method:'GET'},
        [API_VERBS.LB_GET_ONE]:{
            url:'/Zones',
            method:'GET',
            customFilter:{"where":{"uid":"{modelId}"},
                "include": { "relation": "unit" }},
        },
    },

    [RESOURCES.NOTAMS]: {
        [API_VERBS.COUNT]:{url:'/Notams/count', method:'GET'},
        [API_VERBS.LB_GET_MANY]:{url:'/Notams', method:'GET'},
        [API_VERBS.LB_GET_ONE]:{url:'/Notams/{modelId}', method:'GET'},

    },
    [RESOURCES.NOTAM_ACTIVATIONS]: {
        [API_VERBS.LB_GET_MANY_REFERENCE]:{url:'/NotamActivations', method:'GET'},
    },

};
